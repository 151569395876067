// Instagram configuration
export const INSTAGRAM_APP_ID = process.env.NEXT_PUBLIC_INSTAGRAM_APP_ID;
export const INSTAGRAM_APP_SECRET = process.env.INSTAGRAM_APP_SECRET;

// Facebook configuration
export const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
export const FACEBOOK_APP_SECRET = process.env.FACEBOOK_APP_SECRET;

// API versions
export const FACEBOOK_API_VERSION = process.env.NEXT_PUBLIC_FACEBOOK_API_VERSION || process.env.FACEBOOK_API_VERSION || 'v21.0';   
export const INSTAGRAM_API_VERSION = process.env.NEXT_PUBLIC_INSTAGRAM_API_VERSION || process.env.INSTAGRAM_API_VERSION || 'v21.0';

// Base URL and redirects
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || 'https://www.lema-app.com';
export const INSTAGRAM_REDIRECT_URI = process.env.INSTAGRAM_REDIRECT_URI || (BASE_URL + "/api/instagram/callback");
export const FACEBOOK_REDIRECT_URI = process.env.FACEBOOK_REDIRECT_URI || (BASE_URL + "/api/facebook/callback");

// Supabase configuration
export const SUPABASE_URL = process.env.NEXT_PUBLIC_SUPABASE_URL;
export const SUPABASE_ANON_KEY = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;

// Dify API configuration
export const DIFY_API_URL = process.env.DIFY_API_URL;
export const DIFY_PUBLIC_API_URL = process.env.NEXT_PUBLIC_DIFY_API_URL;
export const DIFY_PUBLIC_API_KEY = process.env.NEXT_PUBLIC_DIFY_API_KEY;

/**
 * Retrieves the Dify API key specific to a user from environment variables.
 * This function should ONLY be used server-side where the userId is available.
 * The environment variable name is expected to be in the format DIFY_API_KEY_{TRANSFORMED_USER_ID},
 * where TRANSFORMED_USER_ID is the original userId converted to uppercase with hyphens replaced by underscores.
 *
 * Example .env entry for userId '4c32b04d-4779-49df-b0ac-5cdf5ba629ac':
 * DIFY_API_KEY_4C32B04D_4779_49DF_B0AC_5CDF5BA629AC=app-xxxxxxx
 *
 * Example .env entry for userId 'mock-user-id':
 * DIFY_API_KEY_MOCK_USER_ID=app-yyyyyyy
 *
 * @param userId - The UUID of the user (retrieved from session, Supabase, etc.).
 * @returns The user-specific Dify API key, or undefined if not found.
 */
export const getDifyApiKeyForUser = (userId: string): string | undefined => {
  if (!userId) {
    console.error("getDifyApiKeyForUser called without a userId.");
    return undefined;
  }
  // Transform the userId: uppercase and replace hyphens with underscores
  const transformedUserId = userId.toUpperCase().replace(/-/g, '_');

  // Construct the environment variable name dynamically using the transformed ID
  const keyName = `DIFY_API_KEY_${transformedUserId}`;
  const apiKey = process.env[keyName];

  if (!apiKey) {
    // Log a warning if the key isn't found for a specific user
    console.warn(`Dify API key not found in environment variables for user ${userId} (checked env var: ${keyName})`);
  }

  return apiKey;
};

// Environment configuration
export const IS_DEV = process.env.NODE_ENV === "development";
export const USE_MOCK_AUTH = IS_DEV && process.env.NEXT_PUBLIC_USE_MOCK_AUTH === "true";

// Optionally, provide the mock key specifically for development/mock auth scenarios
// You can access this directly when USE_MOCK_AUTH is true
// Apply the same transformation logic for the mock user ID key lookup
const mockUserIdBase = "mock-user-id"; // Assuming this is the base ID for mock user
const transformedMockUserId = mockUserIdBase.toUpperCase().replace(/-/g, '_'); // MOCK_USER_ID
export const MOCK_DIFY_API_KEY = USE_MOCK_AUTH ? process.env[`DIFY_API_KEY_${transformedMockUserId}`] : undefined;
