import type { User, UserIdentity } from "@supabase/supabase-js";
import { USE_MOCK_AUTH } from "@/config";

// Define mock user for development
export const MOCK_USER: User = {
  id: "mock-user-id",
  app_metadata: {
    provider: "email",
  },
  user_metadata: {
    full_name: "Development User",
    avatar_url: "https://via.placeholder.com/150",
  },
  aud: "authenticated",
  created_at: new Date().toISOString(),
  email: "dev@example.com",
  email_confirmed_at: new Date().toISOString(),
  phone: "",
  confirmed_at: new Date().toISOString(),
  last_sign_in_at: new Date().toISOString(),
  role: "authenticated",
  updated_at: new Date().toISOString(),
};

// Define mock identities
export const MOCK_IDENTITIES: UserIdentity[] = [
  {
    id: "mock-identity-id",
    user_id: MOCK_USER.id,
    identity_data: {
      email: "dev@example.com",
      sub: "mock-user-id",
    },
    provider: "email",
    created_at: new Date().toISOString(),
    last_sign_in_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  }
];

// Development mode flag
export const isDevMode = USE_MOCK_AUTH; 