"use client";

import { createContext, useContext, useEffect, useState, useCallback } from "react";
import { useRouter } from "next/navigation";
import {
  createClient,
  signOut as signOutUser,
  getSession,
  getLinkedIdentities,
  getPrimaryIdentity,
  hasLinkedIdentity
} from "@/auth/utils/client";
import type { User, Provider, UserIdentity } from "@supabase/supabase-js";
import { MOCK_USER, MOCK_IDENTITIES, isDevMode } from "@/auth/mock-user";

type AuthContextType = {
  user: User | null;
  isLoading: boolean;
  identities: UserIdentity[];
  primaryIdentity: UserIdentity | null;
  hasIdentity: (provider: Provider) => boolean;
  signOut: () => Promise<void>;
  refreshUser: () => Promise<void>;
  hasLinkedIdentity: (provider: string) => boolean;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [identities, setIdentities] = useState<UserIdentity[]>([]);
  const [primaryIdentity, setPrimaryIdentity] = useState<UserIdentity | null>(null);
  const router = useRouter();
  const supabase = createClient();

  // Function to refresh the user data and identities
  const refreshUser = useCallback(async () => {
    try {
      setIsLoading(true);

      // If in development mode and mock auth is enabled, use mock user
      if (isDevMode) {
        console.log("Using mock user for development");
        setUser(MOCK_USER);
        setIdentities(MOCK_IDENTITIES);
        setPrimaryIdentity(MOCK_IDENTITIES[0]);
        setIsLoading(false);
        return;
      }
      
      // Original code for production
      const { data, error } = await getSession();
      
      if (error) {
        console.error("Error refreshing session:", error);
        setUser(null);
        setIdentities([]);
        setPrimaryIdentity(null);
        return;
      }
      
      const sessionUser = data.session?.user || null;
      setUser(sessionUser);
      
      if (sessionUser) {
        // Get linked identities
        const linkedIdentities = await getLinkedIdentities(sessionUser);
        setIdentities(linkedIdentities);
        
        // Get primary identity
        const primary = await getPrimaryIdentity(sessionUser);
        setPrimaryIdentity(primary);
      } else {
        setIdentities([]);
        setPrimaryIdentity(null);
      }
    } catch (error) {
      console.error("Error refreshing user:", error);
      setUser(null);
      setIdentities([]);
      setPrimaryIdentity(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Check if user has a specific identity
  const hasIdentity = useCallback((provider: Provider): boolean => {
    // PLACEHOLDER: Always return false for social identities
    // This could be enhanced to check mock data if needed
    if (provider === 'facebook' || provider === 'instagram') {
      console.log(`[PLACEHOLDER] hasIdentity check for ${provider}`);
      return false;
    }
    
    // Original implementation for other identities
    return identities.some(identity => identity.provider === provider);
  }, [identities]);

  // Handle sign out - update to handle mock user
  const handleSignOut = useCallback(async () => {
    try {
      // In development mode, just reset the state
      if (isDevMode) {
        console.log("Mock sign out in development mode");
        // You can choose to reset the mock user or keep it
        // For a true sign-out experience, reset it:
        // setUser(null);
        // setIdentities([]);
        // setPrimaryIdentity(null);

        // For persistent mock authentication, keep it:
        setUser(MOCK_USER);
        setIdentities(MOCK_IDENTITIES);
        setPrimaryIdentity(MOCK_IDENTITIES[0]);

        localStorage.clear(); // Clear local storage on mock sign out

        return;
      }

      // Original production code
      const { error } = await signOutUser();

      if (error) {
        console.error("Error signing out:", error);
        return;
      }

      setUser(null);
      setIdentities([]);
      setPrimaryIdentity(null);
      localStorage.clear(); // Clear local storage on successful sign out

      // Redirect to home page after sign out
      router.push("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  }, [router]);

  // Initialize auth state
  useEffect(() => {
    // If in development mode and mock auth is enabled, use mock user immediately
    if (isDevMode) {
      console.log("Initializing with mock user for development");
      setUser(MOCK_USER);
      setIdentities(MOCK_IDENTITIES);
      setPrimaryIdentity(MOCK_IDENTITIES[0]);
      setIsLoading(false);
      return;
    }
    
    // Original code for production
    refreshUser();

    // Set up auth state change listener
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      const sessionUser = session?.user || null;
      setUser(sessionUser);
      
      if (sessionUser) {
        // Get linked identities
        const linkedIdentities = await getLinkedIdentities(sessionUser);
        setIdentities(linkedIdentities);
        
        // Get primary identity
        const primary = await getPrimaryIdentity(sessionUser);
        setPrimaryIdentity(primary);
      } else {
        setIdentities([]);
        setPrimaryIdentity(null);
      }
      
      setIsLoading(false);
    });

    // Cleanup subscription on unmount
    return () => {
      subscription.unsubscribe();
    };
  }, [refreshUser]);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = {
    user,
    isLoading,
    identities,
    primaryIdentity,
    hasIdentity,
    signOut: handleSignOut,
    refreshUser,
    hasLinkedIdentity,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
