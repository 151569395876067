import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import type { SupabaseClient } from "@supabase/supabase-js";
import type { Database } from "@/lib/database.types";
import { SUPABASE_URL, SUPABASE_ANON_KEY, IS_DEV } from "@/config";

/**
 * Create a type-safe Supabase client for client components
 */
export const createClient = (): SupabaseClient<Database> => {
  // Check if environment variables are properly set
  if (!SUPABASE_URL || !SUPABASE_ANON_KEY) {
    console.error(
      "Supabase configuration missing. Please set NEXT_PUBLIC_SUPABASE_URL and NEXT_PUBLIC_SUPABASE_ANON_KEY in your .env.local file."
    );
    // Return a dummy client that won't throw errors in development
    if (IS_DEV) {
      return createClientComponentClient<Database>({
        supabaseUrl: "https://placeholder.supabase.co",
        supabaseKey: "placeholder-key",
      });
    }
    throw new Error("Supabase configuration missing");
  }

  return createClientComponentClient<Database>();
};

/**
 * Get the current session
 */
export async function getSession() {
  const supabase = createClient();
  return supabase.auth.getSession();
}

/**
 * Get the current user
 */
export async function getUser() {
  const supabase = createClient();
  const {
    data: { session },
  } = await supabase.auth.getSession();
  return session?.user || null;
}

/**
 * Sign out the current user
 */
export async function signOut() {
  const supabase = createClient();
  return supabase.auth.signOut();
}

export const getLinkedIdentities = (user: any) => {
  // Implementation depends on your auth system
  // This is a placeholder implementation
  return user?.identities || [];
};

export const getPrimaryIdentity = (user: any) => {
  // Implementation depends on your auth system
  // This is a placeholder implementation
  const identities = getLinkedIdentities(user);
  return identities.length > 0 ? identities[0] : null;
};

export const hasLinkedIdentity = (user: any, provider: string) => {
  // Implementation depends on your auth system
  // This is a placeholder implementation
  const identities = getLinkedIdentities(user);
  return identities.some((identity: any) => identity.provider === provider);
};
